import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/General.vue")
    },
    {
      path: "/insurance",
      name: "insurance",
      component: () => import("./views/Insurance.vue")
    },
    {
      path: "/healthcare",
      name: "healthcare",
      component: () => import("./views/Healthcare.vue")
    },
    {
      path: "/healthcare/benefits",
      name: "healthcare-benefits",
      component: () => import("./views/General.vue")
    },
    {
      path: "/healthcare/costs",
      name: "healthcare-costs",
      component: () => import("./views/General.vue")
    },
    {
      path: "/healthcare/wording",
      name: "healthcare-wording",
      component: () => import("./views/General.vue")
    },
    {
      path: "/healthcare/apply",
      name: "healthcare-apply",
      component: () => import("./views/General.vue")
    },
    {
      path: "/insurance/apply",
      name: "insurance-apply",
      component: () => import("./views/General.vue")
    },
    {
      path: "/rep",
      name: "rep",
      component: () => import("./views/General.vue")
    },
    {
      path: "/faqs",
      name: "faqs",
      component: () => import("./views/General.vue")
    },
    {
      path: "/regs",
      name: "regs",
      component: () => import("./views/General.vue")
    },
    {
      path: "/news",
      name: "news",
      component: () => import("./views/General.vue")
    },
    {
      path: "/offers",
      name: "offers",
      component: () => import("./views/Offers.vue")
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue")
    },
    {
      path: "/caravan-hire",
      name: "caravan-hire",
      component: () => import("./views/Caravans.vue")
    },
    {
      path: "/request-payment",
      name: "request-payment",
      component: () => import("./views/RequestPayment.vue"),
      meta: { simple: true }
    },
    {
      path: "/request-balance-payment",
      name: "request-balance-payment",
      component: () => import("./views/RequestBalancePayment.vue"),
      meta: { simple: true }
    },
    {
      path: "/refund-deposit",
      name: "refund-deposit",
      component: () => import("./views/RefundDeposit.vue"),
      meta: { simple: true }
    },
    {
      path: "/refund-all",
      name: "refund-all",
      component: () => import("./views/RefundAll.vue"),
      meta: { simple: true }
    },
    {
      path: "/make-payment",
      name: "make-payment",
      component: () => import("./views/MakePayment.vue"),
      meta: { simple: true }
    },
    {
      path: "/make-deposit-payment",
      name: "make-deposit-payment",
      component: () => import("./views/MakeDepositPayment.vue"),
      meta: { simple: true }
    },
    {
      path: "/process",
      name: "process",
      component: () => import("./views/Process.vue"),
      meta: { simple: true }
    },
    {
      path: "/make-balance-payment",
      name: "make-balance-payment",
      component: () => import("./views/MakeBalancePayment.vue"),
      meta: { simple: true }
    },
    {
      path: "/success",
      name: "success",
      component: () => import("./views/Success.vue")
    },
    {
      path: "/cancel",
      name: "cancel",
      component: () => import("./views/Cancel.vue")
    },
    {
      path: "/article/:id",
      name: "article",
      component: () => import("./views/General.vue")
    },
    {
      path: "/:url",
      name: "fallback",
      component: () => import("./views/General.vue")
    }
    // {
    //   path: "/",
    //   name: "root",
    //   component: () => import("./views/Page.vue")
    // },
    // {
    //   path: "/404-not-found",
    //   name: "404",
    //   component: () => import("./views/Page.vue")
    // },
    // {
    //   path: "/:url",
    //   name: "page-by-url",
    //   component: () => import("./views/Page.vue")
    // }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
