import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"
// import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

// const vuexSession = createPersistedState({
//   storage: window.sessionStorage,
//   reducer: state => ({
//     articles: state.articles
//   })
// })

export default new Vuex.Store({
  // plugins: [vuexSession],
  state: {
    page: {},

    site_id: "sypf",
    settings: null,
    main_pages: null,
    articles: null,
    caravans: null,
    showsearch: false,

    homeID: "04NaNjF6E",
    newsID: "156qiMGPW"
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isAboutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.aboutID
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setSearch(state, show) {
      state.showsearch = show
    },
    setPageByArticleID(state, id) {
      var article = state.articles.filter(a => a.url == "/article/" + id)

      var page
      if (article.length) {
        article = article[0]
        page = {
          name: article.title,
          main_text: article["content:encoded"],
          image: article.image,
          meta_desc: article.description
        }
      } else {
        page = {
          name: "Not found",
          main_text: "<p>This page was not found</p>"
        }
      }

      state.page = page
    },
    setRSS(state, rss) {
      rss.items.forEach(article => {
        article.url = article.link

        article.url = article.url.replace("https://sypfcopperconnection.co.uk/?p=", "/article/")
        article.description = article.name

        if (article["media:content"] && article["media:content"].length) {
          var images = article["media:content"].filter(o => o.$.medium && o.$.medium && o.$.medium == "image")
          if (images.length) article.image = images[0].$.url
        }
      })
      state.articles = rss.items
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setArticles(state, articles) {
      state.articles = articles
    },
    setCaravans(state, caravans) {
      state.caravans = caravans
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getArticles({ commit, state }) {
      return new Promise(resolve => {
        if (!state.articles)
          api
            .getPages("image,summary", "page.articles=@" + state.newsID)
            .then(articles => commit("setArticles", articles))
        else resolve()
      })
    },
    getCaravans({ commit, state }) {
      return new Promise(resolve => {
        if (!state.caravans)
          api.getPageByItemID("CARAVANS", "caravans").then(page => commit("setCaravans", page.caravans))
        else resolve()
      })
    }
  }
})
