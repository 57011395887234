import axios from "axios"
import store from "@/store"
import dynamodb from "aws-sdk/clients/dynamodb"

var request = axios.create({
  baseURL:
    window.location.hostname == "localhost"
      ? "https://eiu3du1gbi.execute-api.eu-west-2.amazonaws.com/test/"
      : "https://eiu3du1gbi.execute-api.eu-west-2.amazonaws.com/live/",
  headers: { "Content-Type": "application/json" }
})

var lambda = axios.create({
  baseURL: "/.netlify/functions/",
  headers: { "Content-Type": "application/json" }
})

function parseItem(item) {
  if (item.ItemType) {
    var data = item.ItemType.split("#")
    if (data[1]) item.OrderIndex = data[1]

    data = data[0].split("@")
    if (data[1]) item.ParentID = data[1]

    item.ItemType = data[0].replace(/=+$/g, "")
  }

  return item
}

export default {
  getPages: function(
    ProjectionExpression = "",
    ItemType,
    FilterExpression = "",
    ExpressionAttributeNames = "",
    ExpressionAttributeValues = ""
  ) {
    return new Promise((resolve, reject) => {
      Object.keys(ExpressionAttributeValues).forEach(
        key => (ExpressionAttributeValues[key] = dynamodb.Converter.input(ExpressionAttributeValues[key]))
      )

      var params = {
        ProjectionExpression: "#ItemID, #name, #url" + (ProjectionExpression ? "," + ProjectionExpression : ""),
        TableName: "cms",
        IndexName: "SiteID-ItemType-index",
        KeyConditionExpression: "SiteID = :siteid and begins_with ( ItemType, :itemtype )",
        FilterExpression:
          "#active = :true and (#hide = :false or attribute_not_exists(#hide))" +
          (FilterExpression ? " and " + FilterExpression : ""),
        ExpressionAttributeNames: Object.assign(
          {
            "#ItemID": "ItemID",
            "#name": "name",
            "#url": "url",
            "#hide": "hide",
            "#active": "active"
          },
          ExpressionAttributeNames
        ),
        ExpressionAttributeValues: Object.assign(
          {
            ":siteid": dynamodb.Converter.input(store.state.site_id),
            // Add '=' to end of item type, or '#' if '=' already present
            ":itemtype": dynamodb.Converter.input(ItemType + (ItemType.indexOf("=") > -1 ? "#" : "=")),
            ":false": dynamodb.Converter.input(false),
            ":true": dynamodb.Converter.input(true)
          },
          ExpressionAttributeValues
        )
      }

      request
        .post("getitems?siteid=" + store.state.site_id, params)
        .then(response => {
          resolve(response.data.Items.map(item => dynamodb.Converter.unmarshall(item)))
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPageByURL: function(url) {
    return new Promise((resolve, reject) => {
      request
        .get("getpage?siteid=" + store.state.site_id + "&url=" + url.replace(/^\//, "").replace(/\/$/, ""))
        .then(response => {
          var page = parseItem(dynamodb.Converter.unmarshall(response.data.Items[0]))

          store.commit("setPage", page)

          if (!page || !page.ItemID) store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getHomePage: function() {
    return new Promise((resolve, reject) => {
      request
        .get("getitem?siteid=" + store.state.site_id + "&itemid=" + store.state.homeID)
        .then(response => {
          var page = parseItem(dynamodb.Converter.unmarshall(response.data.Item))

          store.commit("setPage", page)

          if (!page || !page.ItemID) store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPageByItemID: function(itemid, attr = "") {
    return new Promise((resolve, reject) => {
      request
        .get("getitem?siteid=" + store.state.site_id + "&itemid=" + itemid + (attr ? "&attr=" + attr : ""))
        .then(response => resolve(parseItem(dynamodb.Converter.unmarshall(response.data.Item))))
        .catch(error => {
          reject(error)
        })
    })
  },
  getRSS: function() {
    return new Promise((resolve, reject) => {
      if (store.state.articles) {
        resolve()
        return
      }

      lambda
        .get("get-rss")
        .then(response => {
          store.commit("setRSS", response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  requestBooking: function(form) {
    return new Promise((resolve, reject) => {
      lambda
        .post("request-booking", { form })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  requestPayment: function(id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("request-payment", { id })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  requestBalancePayment: function(id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("request-balance-payment", { id })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  refundDeposit: function(id, stripe_id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("refund-deposit", { id, stripe_id })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  refundAll: function(id, stripe_id, stripe_balance_id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("refund-all", { id, stripe_id, stripe_balance_id })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  makePayment: function(id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("make-payment", { id })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  makeDepositPayment: function(id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("make-deposit-payment", { id })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  makeBalancePayment: function(id) {
    return new Promise((resolve, reject) => {
      lambda
        .post("make-balance-payment", { id })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  callFunctions: function() {
    return new Promise((resolve, reject) => {
      var p = []
      p.push(lambda.post("auto-balance-failed-to-pay"))
      p.push(lambda.post("auto-balance-last-chance"))
      p.push(lambda.post("auto-balance-payment"))
      p.push(lambda.post("auto-check-out"))
      p.push(lambda.post("auto-failed-to-pay"))
      p.push(lambda.post("auto-last-chance"))

      Promise.all(p)
        .then(() => resolve())
        .catch(() => reject())
    })
  },
  getBookedDates: function() {
    return new Promise((resolve, reject) => {
      lambda
        .post("get-booked-dates")
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendEmail: function(subject, content) {
    return new Promise((resolve, reject) => {
      request
        .post(
          "submit?siteid=" + store.state.site_id,
          JSON.stringify({
            personalizations: [{ to: [{ email: "southyorks@sypf.polfed.org" }], subject: subject }],
            from: { email: "no-reply@securecms.uk" },
            content: [{ type: "text/html", value: content }]
          })
        )
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  }
}
