<template>
  <div id="app">
    <template v-if="!$route.meta.simple">
      <header class="header shadow">
        <a @click.prevent="navigate('')" class="logo" href>
          <img alt="South Yorkshire Police Federation" src="./assets/logo.png" />
        </a>
      </header>

      <div class="container">
        <a
          @click.prevent="
            showmenu = !showmenu
            $store.commit('setSearch', false)
          "
          aria-label="Menu"
          class="menulink mainmenulink"
          href
        >
          <font-awesome-icon class="icon" icon="bars" v-if="!showmenu" />
          <font-awesome-icon class="icon" icon="times" v-else />
        </a>
        <nav :class="showmenu ? 'reveal' : ''" class="nav">
          <ul>
            <template v-for="item in menu_items">
              <li
                :class="[
                  item.desktop_only ? 'desktoponly' : '',
                  $route.path && $route.path.includes(item.url) ? 'sel' : ''
                ]"
                :key="item.name"
              >
                <a @click.prevent="navigate(item.url)" href>
                  <font-awesome-icon :icon="item.icon" class="icon" />
                  {{ item.name }}
                </a>
              </li>
            </template>
          </ul>
          <div class="lower">
            <p class="legal">
              <router-link to="/privacy-policy">Privacy Policy</router-link>&nbsp;&copy; South Yorkshire Police
              Federation
            </p>
            <p class="website">
              Website designed &amp; developed by
              <a href="https://chameleoncreative.marketing/" rel="noreferrer" target="_blank">Chameleon Creative</a>
            </p>
          </div>
        </nav>

        <div class="search-container">
          <a
            :class="$route.name == 'index' ? '' : 'mobileonly'"
            @click.prevent="
              $store.commit('setSearch', !showsearch)
              showmenu = false
            "
            aria-label="Menu"
            class="menulink"
            href
          >
            <font-awesome-icon icon="search" />
          </a>
          <div :class="showsearch ? 'reveal' : ''" class="search">
            <div class="wrapper shadowalt">
              <input
                @keyup.enter="search"
                aria-label="Quick search"
                class="search-input"
                placeholder="Quick search"
                type="text"
                v-model.trim="query"
              />
              <button @click="search" class="search-btn">GO</button>
            </div>
            <p v-if="noresults">There were no matches to your query</p>
            <router-link :key="result.ItemID" :to="result.url" class="latest shadow" v-for="result in searchresults">
              <span
                :style="{ backgroundImage: 'url(' + result.image + ')' }"
                aria-label="title"
                class="img"
                v-if="result.image"
              ></span>
              <span class="item-content">
                <span class="title">{{ result.name || result.title }}</span>
                <span class="summary" v-if="result.description">{{ result.description }}</span>
                <span class="link">Read more</span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <transition mode="out-in" name="fade">
      <router-view :key="$route.fullPath" />
    </transition>

    <template v-if="!$route.meta.simple">
      <footer class="footer">
        <router-link :class="$route.path && $route.path.includes('insurance') ? 'sel' : ''" to="/insurance">
          <font-awesome-icon class="icon" icon="user-shield" />Insurance
        </router-link>
        <router-link :class="$route.path && $route.path.includes('healthcare') ? 'sel' : ''" to="/healthcare">
          <font-awesome-icon class="icon" icon="heartbeat" />Healthcare
        </router-link>
        <router-link :class="$route.name == 'faqs' ? 'sel' : ''" to="/faqs">
          <font-awesome-icon class="icon" icon="question" />FAQs
        </router-link>
        <router-link :class="$route.name == 'regs' ? 'sel' : ''" to="/regs">
          <font-awesome-icon class="icon" icon="book" />Regs
        </router-link>
      </footer>
      <div class="explainer" v-if="installPrompt">
        <a @click.prevent="installPrompt = false" class="explain-close" href>
          <font-awesome-icon icon="times-circle" />
        </a>
        <div class="explain-install-heading">Install as an app in just 2 taps!</div>
        <div class="explain-install">
          <div>
            Press
            <img alt="Share" class="prompt-img prompt-share" src="/images/ios-share.jpg" />
          </div>
          <div>
            Then
            <img alt="Add to Home" class="prompt-img prompt-add" src="/images/add-to-home.png" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      showmenu: false,
      searchresults: [],
      noresults: false,
      query: "",
      installPrompt: false,
      menu_items: [
        { name: "About Us", url: "about", icon: "info-circle", desktop_only: true },
        { name: "Group Insurance Scheme", url: "insurance", icon: "user-shield", desktop_only: false },
        { name: "Northern Police Healthcare", url: "healthcare", icon: "heartbeat", desktop_only: false },
        { name: "Holiday Caravans", url: "caravan-hire", icon: "caravan", desktop_only: false },
        { name: "FAQs", url: "faqs", icon: "question", desktop_only: false },
        { name: "Police Quick Regs", url: "regs", icon: "book", desktop_only: false },
        { name: "Know Your Local Rep", url: "rep", icon: "user-friends", desktop_only: false },
        { name: "Member Offers", url: "offers", icon: "tags", desktop_only: false },
        { name: "Latest News", url: "news", icon: "rss", desktop_only: false },
        { name: "Contact Us", url: "contact", icon: "phone", desktop_only: false }
      ]
    }
  },
  computed: {
    showsearch() {
      return this.$store.state.showsearch
    }
  },
  methods: {
    navigate: function(url) {
      this.showmenu = false
      this.$router.push("/" + url)
    },
    showInstallPrompt: function() {
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone

      if (isIos() && !isInStandaloneMode()) {
        setTimeout(() => (this.installPrompt = true), 10000)
      }
    },
    search() {
      if (this.query) {
        this.noresults = false
        api
          .getPages(
            "",
            "page.main",
            "contains(#search,:query)",
            { "#search": "_search" },
            { ":query": this.query.toLowerCase() }
          )
          .then(res => {
            this.searchresults = this.$store.state.articles.filter(
              a =>
                a.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
                a.description.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
            this.searchresults = this.searchresults.concat(res)
            if (!this.searchresults.length) this.noresults = true
          })
          .catch(err => console.log(err))
      }
    }
  },
  created() {
    // this.$store.dispatch("getArticles")

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("service-worker.js")
    }

    api.getRSS()
    this.$store.dispatch("getSettings")
    this.$store.dispatch("getCaravans")
  },

  mounted() {
    this.showInstallPrompt()
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none !important;
}

.slicknav_menu {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  overflow-x: hidden;
}

body {
  box-sizing: border-box;
  line-height: 1.8;
  padding-bottom: 7rem;
}

html {
  font-size: 62.5%;
}

body,
input,
select,
textarea {
  font-family: "Titillium Web", sans-serif;
}

input,
select,
textarea {
  border-radius: 3px;
}

.shadown {
  box-shadow: 13px 9px 38px -25px rgba(0, 0, 0, 0.47);
}

.shadowalt {
  box-shadow: 13px 9px 38px 5px rgba(0, 0, 0, 0.1);
}

.form {
  padding: 2rem 0;
}

.form-row {
  margin-top: 1rem;
  display: table;
  width: 100%;
}

.form-label {
  font-size: 1.3rem;
  display: block;
}
.form-text {
  border: 0;
  display: block;
  width: 100%;
  max-width: 550px;
  border-bottom: 1px solid #e2e2e2;
  transition: all 0.2s;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
}
.form-textarea {
  height: 10rem;
}
.form-text:focus {
  border-bottom: 1px solid #05528d;
}
.form .link {
  margin-top: 2rem;
}
.form-strong-label {
  display: block;
  line-height: 1.2;
}

.form-error,
.form-success {
  padding: 1rem 0;
}
.form-error {
  color: #cc0000;
}
.form-success {
  color: seagreen;
}

.button {
  background: #db411d;
  color: white;
  border: 0;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.button svg {
  margin-right: 1rem;
}

.video-wrapper {
  max-width: 50rem;
}

.video {
  margin: 0.5rem 0;
}

.video iframe {
  border-radius: 0.5rem;
}

.header {
  background: #203165;
  text-align: center;
  padding: 1rem;
  padding-bottom: 0.5rem;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
.logo {
  width: 13rem;
  margin: auto;
  display: block;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
.nav {
  position: fixed;
  list-style-type: none;
  background: #203165;
  padding: 2rem 3rem 10rem 3rem;
  position: fixed;
  left: -1000px;
  transition: all 0.4s;
  top: 6.5rem;
  height: calc(100vh - 6.5rem);
  width: 100%;
  z-index: 8;
  opacity: 0;
}
.nav.reveal {
  left: 0;
  opacity: 1;
}
.nav li {
  display: block;
}
.nav > ul > li > a {
  display: block;
  background: rgba(215, 216, 221, 0.1);
  margin-bottom: 1rem;
  padding: 1rem 1.3rem;
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  line-height: 1.4;
}
.nav .sel a {
  color: #febd51;
}

.slogan,
.lower {
  display: none;
}
.desktoponly {
  display: none !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 7;
  background: #334272;
}
.footer > a {
  width: 25%;
  display: block;
  float: left;
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem 0;
  color: white;
  font-weight: bold;
  background-color: #334272;
}

.footer > a.sel {
  background-color: #203165;
}

.footer > a svg {
  display: block;
  color: #febd51;
  font-size: 2rem;
  margin: 0.5rem auto;
}

.content {
  padding: 6.5rem 0;
  padding-bottom: 3rem;
  background: #f5f5f5;
  min-height: 100vh;
}

.content .home h1,
.content .home h2 {
  display: none;
}

.text {
  font-size: 1.5rem;
  padding: 2rem;
}

.text p {
  padding-top: 2rem;
}

.text h1 {
  font-size: 2.5rem;
  color: #203165;
  line-height: 1.2;
  padding: 1.5rem 0;
}

.text h2 {
  font-size: 1.8rem;
  color: #db411d;
  padding-top: 1.5rem;
}

.text a {
  text-decoration: underline;
}

.breadcrumb {
  margin-bottom: 1rem;
  color: #666;
}

.breadcrumb svg {
  margin-right: 0.5rem;
}

.breadcrumb a {
  text-decoration: none;
}

.search-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
}
.wrapper {
  display: block;
  padding: 0 2rem;
  padding-right: 0.75rem;
  border-radius: 0.75rem;
}
.search {
  position: fixed;
  display: block;
  background: white;
  right: -1000px;
  transition: all 0.4s;
  z-index: 8;
  opacity: 0;
  top: 6.75rem;
  width: 100%;
  height: calc(100vh - 6.75rem);
  padding: 3rem 2rem 10rem 2rem;
  overflow: auto;
}
.search.reveal {
  right: 0;
  opacity: 1;
}
.search-input {
  font-size: 1.8rem;
  padding: 1rem 0;
  width: 80%;
  border: 0;
  font-weight: bold;
}
.search-input::placeholder {
  color: rgb(170, 170, 170);
}

.search-btn {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: #db411d;
  color: white;
  border: 0;
  top: 0.8rem;
  float: right;
  position: relative;
}
.menulink {
  color: white;
  font-size: 2.5rem;
  position: relative;
  padding: 2.4rem;
  top: 1.1rem;
  right: 0;
  z-index: 9;
}

.contactlinks {
  display: none;
}

.text ol,
.text ul {
  padding-top: 1rem;
  padding-left: 2rem;
}

.promo {
  border-radius: 0.75rem;
  background-size: cover;
  padding: 1rem 3rem;
}
.promo-title {
  color: white;
  font-size: 4rem;
  line-height: 1.2;
  padding: 1rem 0;
}
.promo-summary {
  color: white;
  font-size: 2rem;
  line-height: 1.4;
}
.promo.insurance {
  background-image: url(./assets/insurance.jpg);
  background-position: center;
  padding-right: 30%;
  padding-bottom: 3rem;
}
.promo.healthcare {
  background-image: url(./assets/healthcare.jpg);
  background-position: center;
  padding-left: 30%;
}
.promo-links {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: white;
}
.promo-links a {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.493);
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  border-radius: 0.75rem;
  transition: all 0.2s;
}
.promo-links a.apply {
  background: #088d0f;
  border: 1px solid #088d0f;
  border-radius: 0.75rem;
}

.inner .promo {
  margin-top: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.ticks {
  padding: 2rem;
  padding-bottom: 0;
}

.v--modal-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}

.v--modal-overlay .v--modal-box {
  max-width: 1000px !important;
  margin: auto;
  left: auto !important;
  padding: 3rem;
}

.modal-close {
  color: #ed2229;
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
  display: block;
  position: absolute;
  right: 1rem;
  top: 0rem;
  text-align: center;
  transition: all 0.2s;
}
.modal-close:hover {
  transform: scale(1.1);
}

.latest {
  background: white;
  margin: 1.5rem;
  display: block;
  border-radius: 0.75rem;
  text-decoration: none !important;
}
.latest .img {
  background-position: center center;
  background-size: cover;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 100%;
  display: block;
  height: 25rem;
}
.latest .title {
  display: block;
  padding: 1rem 2rem;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
  color: #203165;
  line-height: 1.4;
}
.latest .summary {
  display: block;
  padding: 0 2rem 1rem 2rem;
  font-size: 1.2rem;
}
.latest .link {
  display: block;
  font-weight: bold;
  color: #db411d;
  padding: 0 2rem 2rem 2rem;
  font-size: 1.6rem;
}

.text .news {
  margin-top: 3rem;
}

.multiple {
  padding-top: 3rem;
}

.multiple button {
  display: block;
  margin-top: 1rem;
  min-width: 35rem;
  text-align: left;
  max-width: 100%;
}

.explainer {
  position: fixed;
  z-index: 99999999;
  width: 100%;
  max-width: 100%;
  background: rgba(51, 51, 51, 0.8);
  height: 100vh;
  font-size: 1.8rem;
  color: white;
  top: 0;
  left: 0;
}
.blur {
  filter: blur(3px);
  width: 100%;
  height: 100%;
  position: absolute;
}
.explain-close {
  position: absolute;
  top: 3rem;
  right: 5%;
}
.explain-install {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.2;
}
.explain-install > div {
  padding: 0.5rem 2rem;
}
.explain-install-heading {
  position: absolute;
  top: 3.5rem;
  left: 5%;
  font-weight: bold;
}
.prompt-img {
  display: block;
  clear: both;
  margin: 1rem auto;
  border-radius: 0.75rem;
}
.prompt-share {
  background: white;
  padding: 1rem;
  width: 5rem;
}
.prompt-add {
  width: 10rem;
}
.vc-container {
  margin-bottom: 3rem;
}
.vc-popover-content-wrapper {
  width: 180px !important;
  line-height: 1.1 !important;
}

@media screen and (max-width: 360px) {
  .nav > ul > li > a {
    font-size: 1.3rem;
  }
}

.nav li .icon {
  width: 3rem;
  margin-right: 1rem;
  display: inline-block;
  text-align: center;
  color: #febd51;
}
.menulink {
  color: white;
  font-size: 3rem;
  position: relative;
  padding: 2.4rem;
  top: 0.5rem;
  z-index: 9;
}

@media screen and (max-height: 750px) {
  .lower {
    position: relative !important;
    padding-top: 4rem;
  }
}
@media screen and (max-width: 750px) {
  .promo {
    padding: 25rem 2rem 2rem 2rem !important;
  }
  .promo.promo.healthcare {
    background-image: url(./assets/healthcare-mobile.jpg);
  }
  .promo.promo.insurance {
    background-image: url(./assets/insurance-mobile.jpg);
  }
  .promo-title {
    font-size: 3rem;
  }
  .promo-summary {
    font-size: 1.6rem;
  }
  .promo-links {
    margin-top: 2rem;
  }
  .promo-links a {
    font-size: 1.6rem;
    border: 0;
    border-radius: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.493);
    display: block;
  }
  .v--modal-overlay .v--modal-box {
    min-width: 94%;
    max-height: 90vh;
    overflow: auto !important;
  }
}
@media screen and (max-width: 1049px) {
  .promo {
    margin: 2rem 1.5rem;
    box-shadow: 13px 9px 38px -25px rgba(0, 0, 0, 0.47);
  }
  .block {
    display: none;
  }
  .inner .promo {
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  .text .latest {
    margin: 1.5rem 0;
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 950px) {
  .footer {
    display: none;
  }
}
@media screen and (min-width: 1050px) {
  body {
    padding-bottom: 0;
  }
  .mobileonly {
    display: none;
  }
  .header {
    width: 30rem;
    padding-top: 5.5rem;
    height: 11rem;
  }
  .desktoponly {
    display: block !important;
  }
  .nav .icon {
    display: none !important;
  }
  .mainmenulink {
    display: none;
  }
  .nav {
    width: 30rem;
    left: 0;
    opacity: 1;
    padding: 2rem;
    overflow: auto;
    top: 11rem;
    height: calc(100vh - 11rem);
  }
  .nav a:hover {
    color: #febd51;
  }

  .nav > ul > li:last-child > a {
    border-bottom: 0;
  }
  .nav > ul > li > a {
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0.5rem 0;
    transition: all 0.2s;
    padding: 1.3rem;
  }
  .slogan {
    display: block;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0 2rem;
  }
  .lower {
    display: block;
    position: absolute;
    bottom: 2rem;
    color: white;
  }
  .legal {
    opacity: 0.6;
  }

  .content {
    width: calc(100% - 30rem);
    left: 30rem;
    position: relative;
    padding: 1rem 7rem 3rem 3rem;
  }
  .content .home h1 {
    display: block;
    font-size: 6rem;
    padding: 3rem;
    line-height: 1.2;
    color: #203165;
  }

  .content .home h2 {
    display: block;
    font-size: 3rem;
    padding: 3rem;
    padding-top: 0;
    line-height: 1.2;
    color: #db411d;
  }
  .text h1 {
    font-size: 3.5rem;
    padding: 3rem 0;
  }
  .text h2 {
    font-size: 2.5rem;
  }
  .menulink {
    padding-bottom: 1rem;
  }
  .menulink,
  .contactlinks a {
    color: #203165;
    font-size: 3rem;
    top: 1.6rem;
    transition: all 0.2s;
  }
  .search {
    top: 0;
    right: -3000px;
    padding: 2rem 2rem 2rem 6rem;
    width: calc(100% - 35rem);
    height: 100vh;
  }
  .wrapper {
    width: 95%;
  }

  .contactlinks {
    display: block;
    position: relative;
    right: 0;
    top: 1.8rem;
    z-index: 2;
  }
  .contactlinks a {
    display: block;
    padding: 0 2.4rem;
  }
  .contactlinks a:hover,
  .menulink:hover {
    color: #db411d;
  }
  .button {
    margin-top: 4rem;
  }

  .question:hover {
    background: rgb(223, 223, 223);
  }
  .latest .summary {
    font-size: 1.5rem;
  }
  .news {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .video-wrapper {
    display: none;
  }
}
@media screen and (min-width: 1250px) {
  .header {
    width: 35rem;
  }
  .nav {
    width: 35rem;
  }
  .content {
    width: calc(100% - 35rem);
    left: 35rem;
  }
}

@media screen and (min-width: 1600px) {
  .promo.insurance {
    padding-right: 45%;
  }
  .promo.healthcare {
    padding-left: 45%;
  }

  .latest {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .latest .img {
    position: relative;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
    height: auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
