import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VModal from "vue-js-modal"
import VueAnalytics from "vue-analytics"
import VCalendar from 'v-calendar';

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faStopwatch,
  faUserFriends,
  faTags,
  faRss,
  faPhone,
  faHeartbeat,
  faQuestion,
  faBook,
  faUserShield,
  faBars,
  faTimes,
  faSearch,
  faPlay,
  faEnvelope,
  faInfoCircle,
  faChevronLeft,
  faCloudDownloadAlt,
  faTimesCircle,
  faMapMarkerAlt,
  faMale,
  faBed,
  faExternalLinkAlt,
  faCalendarCheck,
  faChevronRight,
  faCheck,
  faSpinner,
  faCaravan
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faStopwatch,
  faUserFriends,
  faTags,
  faRss,
  faPhone,
  faHeartbeat,
  faQuestion,
  faBook,
  faUserShield,
  faBars,
  faTimes,
  faSearch,
  faPlay,
  faEnvelope,
  faInfoCircle,
  faChevronLeft,
  faCloudDownloadAlt,
  faTimesCircle,
  faMapMarkerAlt,
  faBed,
  faMale,
  faExternalLinkAlt,
  faCalendarCheck,
  faChevronRight,
  faCheck,
  faSpinner,
  faCaravan
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === "production"
Vue.use(VModal)
Vue.use(Meta)
Vue.use(VueAnalytics, {
  id: "UA-8386718-4",
  router,
  autoTracking: {
    pageviewOnLoad: false
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})
Vue.use(VCalendar, {
  firstDayOfWeek: 2
});

router.beforeEach((to, from, next) => {
  store.commit("setSearch", false)
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
