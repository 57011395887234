<template>
  <button
    class="button"
    @click="openDoc(page.downloads[0].file)"
    v-if="page.downloads && page.downloads.length == 1"
  >
    <font-awesome-icon class="icon" icon="cloud-download-alt" />
    {{ page.downloads[0].name }}
  </button>

  <div class="multiple" v-else-if="page.downloads && page.downloads.length > 1">
    <template v-if="page.ItemID !== 'nL7MIVtBq'">
      <button
        :key="download._id"
        @click="openDoc(download.file)"
        class="button"
        v-for="download in page.downloads"
      >
        <font-awesome-icon class="icon" icon="cloud-download-alt" />
        {{ download.name }}
      </button>
    </template>
    <template v-else>
      <button
        :key="download._id"
        @click.prevent="showDownload(download)"
        class="button"
        v-for="download in page.downloads"
      >
        <font-awesome-icon class="icon" icon="info-circle" />
        {{ download.name }}
      </button>
    </template>
    <modal :scrollable="true" height="auto" name="download" width="90%">
      <a @click.prevent="$modal.hide('download')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid">
        <div>
          <h2>{{ dlToShow.name }}</h2>
          <div v-html="dlToShow.description"></div>
          <button @click="openDoc(dlToShow.file)" class="button">
            <font-awesome-icon class="icon" icon="cloud-download-alt" />Download
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "Downloads",
  data: function() {
    return {
      dlToShow: {}
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  },
  methods: {
    showDownload(dl) {
      this.dlToShow = dl
      this.$modal.show("download")
    },
    openDoc(doc) {
      window.open(doc)
    }
  }
}
</script>
